@import url(https://fonts.googleapis.com/css?family=Crete+Round:200,300,400,700);
$headings-font-family:Crete Round;

$primary:#528078;
$secondary:#eed75a;
$success:#FEC100;
$danger:#703B3B;
$info:#63e792;
$warning:#FFE869;
$light:#FDFBF7;
$dark:#555555;
$enable-gradients:false;
$spacer:1rem;
$border-width:1px;
$btn-border-radius:.25rem;
$enable-negative-margins: true;

@import '~bootstrap/scss/bootstrap.scss';
@import url('https://cdn.jsdelivr.net/gh/SebastianAigner/twemoji-amazing@1.0.0/twemoji-amazing.css');

.btn-outline-primary:hover {
  color: $light;
}

.card-hover-expand {
  transition: 0.3s;
  position: relative;
  z-index: 5;
}

.card-hover-expand:hover {
  transform: scale(1.1);
  z-index: 10;
  border-left: rgba(0, 0, 0, 0.125) 1px solid !important;
}

.setup-whitelist-container {
  background-color: tint-color($primary, 70%);
}

.setup-blocked-container {
  background-color: $red-100;
}

.setup-potential-container {
  background-color: tint-color($success, 70%);
}

.btn-primary {
  color: $light !important;
}

.email-container > * {
  flex: 0 0 auto;
  width: 25%;
}

.steps-container {
  gap: 1.5rem;
}

.steps-dash {
  width: 3rem;
}

.configure-container :last-child {
  text-align: end;
}

@media(max-width: 1025px) {
  .steps-dash {
    width: 1.5rem;
  }
}

@media(max-width: 991px) {
  .email-container > * {
    width: 33.33333%;
  }
  
}

@media(max-width: 800px) {
  .steps-dash {
    display: none;
  }
  
  .steps-container {
    gap: 1rem;
  }
}

@media(max-width: 767px) {
  .email-container > * {
    width: 50%;
  }
  
  .steps-container {
    flex-direction: column;
    gap: 0.25rem;
  }

  .configure-container {
    flex-direction: column;
  }

  .configure-container > * {
    text-align: center;
  }

  .configure-container > h2 {
    margin-bottom: 1rem;
  }
}

@media(max-width: 500px) {
  .email-container > * {
    width: 100%;
  }
}